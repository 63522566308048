/* Generated by Glyphter (http://www.glyphter.com) on  Sat Sep 17 2022*/
@font-face {
    font-family: 'Glyphter';
    src: url('../fonts/Glyphter.eot');
    src: url('../fonts/Glyphter.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Glyphter.woff') format('woff'),
         url('../fonts/Glyphter.ttf') format('truetype'),
         url('../fonts/Glyphter.svg#Glyphter') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class*='icon-']:before{
	display: inline-block;
   font-family: 'Glyphter';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
/*------------------------------------------*/
/*  VECTOR ICONS
/*------------------------------------------*/

.ico-rounded-xs [class*="icon-"]:before, .ico-rounded-xs [class*="icon-"]:after { line-height: 70px!important; }
.ico-rounded-sm [class*="icon-"]:before, .ico-rounded-sm [class*="icon-"]:after { line-height: 80px!important; }
.ico-rounded-md [class*="icon-"]:before, .ico-rounded-md [class*="icon-"]:after { line-height: 90px!important; }
.ico-rounded-lg [class*="icon-"]:before, .ico-rounded-lg [class*="icon-"]:after { line-height: 95px!important; }
.ico-rounded-xl [class*="icon-"]:before, .ico-rounded-xl [class*="icon-"]:after { line-height: 110px!important; }

.ico-10 [class*="icon-"]:before, .ico-10 [class*="icon-"]:after { font-size: 0.75rem; }     /* 15px */
.ico-15 [class*="icon-"]:before, .ico-15 [class*="icon-"]:after { font-size: 0.9375rem; }   /* 15px */
.ico-20 [class*="icon-"]:before, .ico-20 [class*="icon-"]:after { font-size: 1.25rem; }     /* 20px */
.ico-25 [class*="icon-"]:before, .ico-25 [class*="icon-"]:after { font-size: 1.5625rem; }   /* 25px */
.ico-30 [class*="icon-"]:before, .ico-30 [class*="icon-"]:after { font-size: 1.875rem; }    /* 30px */
.ico-35 [class*="icon-"]:before, .ico-35 [class*="icon-"]:after { font-size: 2.1875rem; }   /* 35px */
.ico-40 [class*="icon-"]:before, .ico-40 [class*="icon-"]:after { font-size: 2.5rem; }      /* 40px */
.ico-45 [class*="icon-"]:before, .ico-45 [class*="icon-"]:after { font-size: 2.8125rem; }   /* 45px */
.ico-50 [class*="icon-"]:before, .ico-50 [class*="icon-"]:after { font-size: 3.125rem; }    /* 50px */
.ico-55 [class*="icon-"]:before, .ico-55 [class*="icon-"]:after { font-size: 3.4375rem; }   /* 55px */
.ico-60 [class*="icon-"]:before, .ico-60 [class*="icon-"]:after { font-size: 3.75rem; }     /* 60px */
.ico-65 [class*="icon-"]:before, .ico-65 [class*="icon-"]:after { font-size: 4.0625rem; }   /* 65px */
.ico-70 [class*="icon-"]:before, .ico-70 [class*="icon-"]:after { font-size: 4.375rem; }    /* 70px */
.ico-75 [class*="icon-"]:before, .ico-75 [class*="icon-"]:after { font-size: 4.6875rem; }   /* 75px */
.ico-80 [class*="icon-"]:before, .ico-80 [class*="icon-"]:after { font-size: 5rem; }        /* 80px */
.ico-85 [class*="icon-"]:before, .ico-85 [class*="icon-"]:after { font-size: 5.3125rem; }   /* 85px */
.ico-90 [class*="icon-"]:before, .ico-90 [class*="icon-"]:after { font-size: 5.625rem; }    /* 90px */
.ico-95 [class*="icon-"]:before, .ico-95 [class*="icon-"]:after { font-size: 5.9375rem; }   /* 95px */
.ico-100 [class*="icon-"]:before, .ico-100 [class*="icon-"]:after { font-size: 6.25rem; }   /* 100px */
.ico-105 [class*="icon-"]:before, .ico-105 [class*="icon-"]:after { font-size: 6.5625rem; } /* 105px */
.ico-110 [class*="icon-"]:before, .ico-110 [class*="icon-"]:after { font-size: 6.875rem; }  /* 110px */
.ico-115 [class*="icon-"]:before, .ico-115 [class*="icon-"]:after { font-size: 7.1875rem; } /* 115px */
.ico-120 [class*="icon-"]:before, .ico-120 [class*="icon-"]:after { font-size: 7.5rem; }    /* 120px */
.ico-125 [class*="icon-"]:before, .ico-125 [class*="icon-"]:after { font-size: 7.8125rem; } /* 125px */

.icon-logistic01:before{content:'\0041';}
.icon-logistic02:before{content:'\0042';}
.icon-logistic03:before{content:'\0043';}
.icon-logistic04:before{content:'\0044';}
.icon-logistic05:before{content:'\0045';}
.icon-logistic06:before{content:'\0046';}
.icon-logistic07:before{content:'\0047';}
.icon-logistic08:before{content:'\0048';}
.icon-logistic09:before{content:'\0049';}
.icon-logistic10:before{content:'\004a';}
.icon-logistic11:before{content:'\004b';}
.icon-logistic12:before{content:'\004c';}
.icon-logistic13:before{content:'\004d';}
.icon-logistic14:before{content:'\004e';}
.icon-logistic15:before{content:'\004f';}
.icon-logistic16:before{content:'\0050';}
.icon-logistic17:before{content:'\0051';}
.icon-logistic18:before{content:'\0052';}
.icon-logistic19:before{content:'\0053';}
.icon-logistic20:before{content:'\0054';}
.icon-logistic21:before{content:'\0055';}
.icon-logistic22:before{content:'\0056';}
.icon-logistic23:before{content:'\0057';}
.icon-logistic24:before{content:'\0058';}
.icon-logistic25:before{content:'\0059';}
.icon-logistic26:before{content:'\005a';}
.icon-logistic27:before{content:'\0061';}
.icon-logistic28:before{content:'\0062';}
.icon-logistic29:before{content:'\0063';}
.icon-logistic30:before{content:'\0064';}
.icon-logistic31:before{content:'\0065';}
.icon-logistic32:before{content:'\0066';}
.icon-logistic33:before{content:'\0067';}
.icon-logistic34:before{content:'\0068';}
.icon-logistic35:before{content:'\0069';}
.icon-logistic36:before{content:'\006a';}
.icon-logistic37:before{content:'\006b';}
.icon-logistic38:before{content:'\006c';}
.icon-logistic39:before{content:'\006d';}
.icon-logistic40:before{content:'\006e';}